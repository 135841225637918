import { Fragment, PropsWithChildren, createContext, useCallback, useContext, useState } from 'react';

export type CreateOverlayElement = (props: { isOpen: boolean; close: () => void }) => JSX.Element;

type OverlayElementsValue = {
  element: CreateOverlayElement;
  isOpen: boolean;
};

const OverlayContext = createContext<{
  mount: (id: string, renderComponent: CreateOverlayElement) => void;
  unmount: (id: string) => void;
  clearOverlays: () => void;
} | null>(null);

const OverlayProvider = ({ children }: PropsWithChildren) => {
  const [overlayElements, setOverlayElements] = useState<Map<string, OverlayElementsValue>>(new Map());

  const mount = useCallback((id: string, element: CreateOverlayElement) => {
    setOverlayElements((prevOverlayElements) => {
      const clonedMap = new Map(prevOverlayElements);
      clonedMap.set(id, {
        element: element,
        isOpen: true,
      });

      return clonedMap;
    });
  }, []);

  const unmount = useCallback((id: string) => {
    setOverlayElements((prevOverlayElements) => {
      const clonedMap = new Map(prevOverlayElements);
      clonedMap.delete(id);

      return clonedMap;
    });
  }, []);

  const clearOverlays = useCallback(() => {
    setOverlayElements(new Map());
  }, []);

  return (
    <OverlayContext.Provider value={{ mount, unmount, clearOverlays }}>
      {children}
      {[...overlayElements.entries()].map(([id, value]) => (
        <Fragment key={id}>
          {value.element({
            isOpen: value.isOpen,
            close: () => unmount(id),
          })}
        </Fragment>
      ))}
    </OverlayContext.Provider>
  );
};

const useOverlayContext = () => {
  const overlayContext = useContext(OverlayContext);

  if (!overlayContext) {
    throw Error('useOverlay hook should be used in OverlayProvider.');
  }

  return overlayContext;
};

export { OverlayProvider, useOverlayContext };
