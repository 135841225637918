import { UserDetails, UserProfileDetails } from 'types/account/internal';

export type UserType = 'super' | 'brand' | 'influencer' | 'rejected' | 'partner' | 'verified' | 'normal';

export const loginPermission = (user: UserDetails) => {
  const { is_staff, account_type, email_valid } = user;
  // staff and partner can access
  if (is_staff || account_type === 5) return 'accessAvailable';
  // influencer, rejected account block
  else if (account_type !== 2) throw { response: { data: "You don't have permission." } };
  // normal user and business user need to authenticate email
  else if (!email_valid) return 'AuthenticateEmail';
  // business user who authenticated email can access
  else return 'accessAvailable';
};

export const userType = (user: UserDetails | UserProfileDetails): UserType => {
  const { is_staff, account_type, username } = user;
  if (is_staff || username === 'NuriLounge') return 'super';
  else if (account_type === 2) return 'brand';
  else if (account_type === 3) return 'influencer';
  else if (account_type === 4) return 'rejected';
  else if (account_type === 5) return 'partner';
  else if (account_type === 6) return 'verified';
  else return 'normal';
};
export const userTypeToAccountType = (userType: UserType): number => {
  switch (userType) {
    case 'super':
      return 0;
    case 'brand':
      return 2;
    case 'influencer':
      return 3;
    case 'rejected':
      return 4;
    case 'partner':
      return 5;
    case 'verified':
      return 6;
    default:
      return 1;
  }
};

export const userTypeShortName = (user: UserDetails | UserProfileDetails): string => {
  const type = userType(user);
  switch (type) {
    case 'super':
      return 'Staff';
    case 'brand':
      return 'Brand';
    case 'influencer':
      return 'Creator';
    case 'rejected':
      return 'Rejected';
    case 'partner':
      return 'Partner';
    case 'verified':
      return 'Verified';
    default:
      return 'Member';
  }
};

export const userTypeFullName = (user: UserDetails | UserProfileDetails): string => {
  const type = userType(user);
  switch (type) {
    case 'super':
      return 'Staff';
    case 'brand':
      return 'Brand';
    case 'influencer':
      return 'Creator';
    case 'rejected':
      return 'Rejected';
    case 'partner':
      return 'Partner';
    case 'verified':
      return 'Verified Creator';
    default:
      return 'Member';
  }
};

export const showSettingTab = (user: UserDetails) => userType(user) !== 'super';

export const upperTabMenu = (
  user: UserDetails
): { primary: string; pathname: string; check: (p: string) => boolean }[] => {
  const type = userType(user);
  const dashboard = {
    primary: 'Dashboard',
    pathname: '',
    check: (pathname: string) => pathname.split('/')?.[1] === '',
  };
  const chat = {
    primary: 'Chat',
    pathname: 'chat',
    check: (pathname: string) => pathname.split('/')?.[1] === 'chat',
  };
  const banner = {
    primary: 'Banner',
    pathname: 'banner',
    check: (pathname: string) => pathname.split('/')?.[1] === 'banner',
  };
  const benefit = {
    primary: 'Benefit',
    pathname: 'list/benefit',
    check: (pathname: string) => pathname.split('/')?.[2] === 'benefit',
  };
  const quest = {
    primary: 'Quest',
    pathname: 'list/quest',
    check: (pathname: string) => pathname.split('/')?.[2] === 'quest',
  };
  const users = {
    primary: 'Users',
    pathname: 'users',
    check: (pathname: string) => pathname.split('/')?.[1] === 'users',
  };
  const client = {
    primary: 'Client',
    pathname: 'client',
    check: (pathname: string) => pathname.split('/')?.[1] === 'client',
  };
  const myJelly = {
    primary: 'My Jelly',
    pathname: 'point/my-jelly',
    check: (pathname: string) => pathname.split('/')?.[1] === 'point' && pathname.split('/')?.[2] === 'my-jelly',
  };
  const myCredit = {
    primary: 'My Credit',
    pathname: 'point/my-credit',
    check: (pathname: string) => pathname.split('/')?.[1] === 'point' && pathname.split('/')?.[2] === 'my-credit',
  };
  const payout = {
    primary: 'Payout',
    pathname: 'payout',
    check: (pathname: string) => pathname.split('/')?.[1] === 'payout',
  };
  const analytics = {
    primary: 'Analytics',
    pathname: 'analytics',
    check: (pathname: string) => pathname.split('/')?.[1] === 'analytics',
  };
  const notifications = {
    primary: 'Notifications',
    pathname: 'notifications',
    check: (pathname: string) => pathname.split('/')?.[1] === 'notifications',
  };

  switch (type) {
    case 'partner':
      return [dashboard, chat, banner, benefit, quest, users, notifications];
    case 'super':
      return [
        dashboard,
        chat,
        banner,
        benefit,
        quest,
        users,
        client,
        myJelly,
        myCredit,
        payout,
        analytics,
        notifications,
      ];
    case 'brand':
      return [dashboard, chat, benefit, quest, myJelly, myCredit];
    default:
      return [];
  }
};
