import { ReactComponent as ArrowBottom } from 'assets/SVG/menu/ArrowBottom.svg';
import { ReactComponent as ArrowRight } from 'assets/SVG/menu/ArrowRight.svg';
import { ReactComponent as ChevronBottom } from 'assets/SVG/menu/ChevronBottom.svg';
import { ReactComponent as ChevronLeft } from 'assets/SVG/menu/ChevronLeft.svg';
import { ReactComponent as ChevronRight } from 'assets/SVG/menu/ChevronRight.svg';
import { ReactComponent as ChevronTop } from 'assets/SVG/menu/ChevronTop.svg';
import { ReactComponent as Filter } from 'assets/SVG/menu/Filter.svg';
import { ReactComponent as Fries } from 'assets/SVG/menu/Fries.svg';
import { ReactComponent as Hamburger } from 'assets/SVG/menu/Hamburger.svg';
import { ReactComponent as Horizontal } from 'assets/SVG/menu/Horizontal.svg';
import { ReactComponent as Kebab } from 'assets/SVG/menu/Kebab.svg';
import { ReactComponent as ListBullet } from 'assets/SVG/menu/ListBullet.svg';
import { ReactComponent as Minus } from 'assets/SVG/menu/Minus.svg';
import { ReactComponent as Multiply } from 'assets/SVG/menu/Multiply.svg';
import { ReactComponent as Plus } from 'assets/SVG/menu/Plus.svg';
import { ReactComponent as Side } from 'assets/SVG/menu/Side.svg';
import { ReactComponent as Vertical } from 'assets/SVG/menu/Vertical.svg';

import { ColorTypes, color } from 'styles/assets';

type IconType =
  | 'arrow_bottom'
  | 'arrow_right'
  | 'chevron_bottom'
  | 'chevron_left'
  | 'chevron_right'
  | 'chevron_top'
  | 'filter'
  | 'fries'
  | 'hamburger'
  | 'horizontal'
  | 'kebab'
  | 'list_bullet'
  | 'minus'
  | 'multiply'
  | 'plus'
  | 'side'
  | 'vertical';

interface Props {
  type: IconType;
  width?: number;
  height?: number;
  fill?: ColorTypes;
  strokeWidth?: number;
  testID?: string;
}

const MenuIcon = ({ type, width = 24, height = 24, fill = 'black', strokeWidth, testID }: Props) => {
  const defaultProps: any = { width, height, color: color[fill], 'data-testid': testID || `menu_${type}` };
  if (strokeWidth) defaultProps['strokeWidth'] = strokeWidth;

  switch (type) {
    case 'arrow_bottom':
      return <ArrowBottom {...defaultProps} />;
    case 'arrow_right':
      return <ArrowRight {...defaultProps} />;
    case 'chevron_bottom':
      return <ChevronBottom {...defaultProps} />;
    case 'chevron_left':
      return <ChevronLeft {...defaultProps} />;
    case 'chevron_right':
      return <ChevronRight {...defaultProps} />;
    case 'chevron_top':
      return <ChevronTop {...defaultProps} />;
    case 'filter':
      return <Filter {...defaultProps} />;
    case 'fries':
      return <Fries {...defaultProps} />;
    case 'hamburger':
      return <Hamburger {...defaultProps} />;
    case 'horizontal':
      return <Horizontal {...defaultProps} />;
    case 'kebab':
      return <Kebab {...defaultProps} />;
    case 'list_bullet':
      return <ListBullet {...defaultProps} />;
    case 'minus':
      return <Minus {...defaultProps} />;
    case 'multiply':
      return <Multiply {...defaultProps} />;
    case 'plus':
      return <Plus strokeWidth={1.5} {...defaultProps} />;
    case 'side':
      return <Side {...defaultProps} />;
    case 'vertical':
      return <Vertical {...defaultProps} />;
  }
};

export default MenuIcon;
