import React, { MouseEvent } from 'react';

import styled, { css } from 'styled-components';

import { ColorTypes, color } from 'styles/assets';

interface Props {
  width?: string;
  height?: string;
  borderRadius?: string;
  backgroundColor?: string;
  type?: any;
  disabled?: boolean;
  onPress?: (e: MouseEvent<HTMLButtonElement>) => void;
  children: React.ReactNode;
  border?: BorderProps;
  hover?: HoverProps;
  'data-testid'?: string;
}

interface BorderProps {
  borderWidth: string;
  borderStyle: string;
  borderColor: ColorTypes;
}

interface HoverProps {
  backgroundColor?: ColorTypes;
  fontColor: ColorTypes;
  border?: BorderProps;
}

const ButtonShape = ({
  children,
  width = '342px',
  height = '50px',
  borderRadius = '6px',
  backgroundColor = color.violet39,
  border,
  disabled = false,
  onPress,
  hover,
  type = 'button',
}: Props) => {
  return (
    <Container
      width={width}
      height={height}
      borderRadius={borderRadius}
      backgroundColor={backgroundColor}
      border={border}
      onClick={onPress}
      disabled={disabled}
      type={type}
      hover={hover}
    >
      {children}
    </Container>
  );
};

export default ButtonShape;

const BorderStyles = css<{ border?: BorderProps }>`
  border-width: ${(props) => props.border?.borderWidth || '0px'};
  border-style: ${(props) => props.border?.borderStyle || 'solid'};
  border-color: ${(props) => (props.border?.borderColor ? color[props.border.borderColor] : 'inherit')};
`;
const HoverStyles = css<{ hover?: HoverProps; backgroundColor?: string }>`
  &:hover {
    ${BorderStyles}
    background-color: ${(props) =>
      props.hover?.backgroundColor ? color[props.hover.backgroundColor] : props.backgroundColor};
    color: ${(props) => (props.hover?.fontColor ? color[props.hover.fontColor] : 'inherit')};
  }
`;
const Container = styled.button<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  transition: all 0.3s ease;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border-radius: ${(props) => props.borderRadius};
  background-color: ${(props) => props.backgroundColor};
  ${BorderStyles}
  ${HoverStyles}
  &:disabled {
    cursor: not-allowed;
  }
  &:disabled,
  &:disabled:hover {
    opacity: 1;
  }
`;
