import { useEffect, useState } from 'react';

import { CreateOverlayElement, useOverlayContext } from 'providers/overlay-provider';

let elementId = 0;

export const useOverlay = () => {
  const { mount, unmount, clearOverlays } = useOverlayContext();
  const [id] = useState(() => String(elementId++));

  useEffect(() => {
    const handleRouteChange = () => {
      clearOverlays();
    };
    window.addEventListener('popstate', handleRouteChange);
    window.addEventListener('hashchange', handleRouteChange);

    return () => {
      window.removeEventListener('popstate', handleRouteChange);
      window.removeEventListener('hashchange', handleRouteChange);
    };
  }, [clearOverlays]);

  return {
    open: (props: CreateOverlayElement) => {
      mount(id, props);
    },
    close: () => unmount(id),
  };
};
