import { Tabs } from '@mui/material';
import Tab from '@mui/material/Tab';

interface Props {
  tab: string;
  handleChange: (e: React.SyntheticEvent, newValue: string) => void;
  tabArr: Array<{ label: string; value: string }>;
}

const ListTab = ({ tab, handleChange, tabArr }: Props) => {
  const getTabProps = (index: string): object => {
    return {
      id: `simple-tab-${index}`,
      key: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
      value: index,
    };
  };

  return (
    <Tabs style={{ minHeight: 40 }} value={tab} onChange={handleChange} className={'tabBtn-container'}>
      {tabArr.map((el) => (
        <Tab key={el.value} style={{ minHeight: 40 }} {...el} {...getTabProps(el.value)} />
      ))}
    </Tabs>
  );
};

export default ListTab;
