import styled, { css } from 'styled-components';

import { ColorTypes, color } from 'styles/assets';

interface Props {
  children?: React.ReactNode;
  direction?: 'bottom' | 'right' | 'top';
  padding?: string;
  backgroundColor?: ColorTypes;
  borderColor?: ColorTypes;
}

const Ballon = ({
  children,
  direction = 'bottom',
  padding = '17px',
  backgroundColor = 'violet39',
  borderColor = 'violet39',
}: Props) => {
  return (
    <BallonContainer direction={direction}>
      <Rectangle backgroundColor={backgroundColor} borderColor={borderColor} direction={direction} />
      <Box backgroundColor={backgroundColor} borderColor={borderColor} padding={padding}>
        {children}
      </Box>
    </BallonContainer>
  );
};

export default Ballon;

const balloonDirection = {
  bottom: css`
    flex-direction: column;
  `,
  right: css`
    flex-direction: row;
    align-items: center;
  `,
  top: css`
    flex-direction: column-reverse;
  `,
};

const BallonContainer = styled.div<{ direction: Props['direction'] }>`
  display: flex;
  width: 100%;
  ${({ direction }) => balloonDirection[direction as keyof typeof balloonDirection]}
`;

const rectangleRotation = {
  bottom: css`
    transform: rotate(-60deg) skewX(-30deg) scale(1, 0.866);
    margin: 0 auto -8px auto;
  `,
  right: css`
    transform: rotate(-120deg) skewY(-30deg) scale(0.866, 1);
    margin: auto -8px auto 0;
  `,
  top: css`
    transform: rotate(-240deg) skewX(-30deg) scale(1, 0.866);
    margin: -8px auto 0 auto;
  `,
};

const Rectangle = styled.div<{ direction?: Props['direction']; backgroundColor: ColorTypes; borderColor: ColorTypes }>`
  content: '';
  position: relative;
  background-color: ${({ backgroundColor }) => color[backgroundColor]};
  border-top: 1px solid ${({ borderColor }) => color[borderColor]};
  border-right: 1px solid ${({ borderColor }) => color[borderColor]};
  text-align: left;
  width: 16px;
  height: 16px;
  border-top-right-radius: 30%;
  ${({ direction }) => rectangleRotation[direction as keyof typeof rectangleRotation]}
  &::before,
  &::after {
    content: '';
    position: absolute;
    background-color: inherit;
    width: 14px;
    height: 14px;
    border-top-right-radius: 30%;
  }
  &::before {
    transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707) translate(0, -50%);
  }
  &::after {
    transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414) translate(50%);
  }
`;

const Box = styled.div<{ padding: string; backgroundColor: ColorTypes; borderColor: ColorTypes }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${({ backgroundColor }) => color[backgroundColor]};
  border: 1px solid ${({ borderColor }) => color[borderColor]};
  border-radius: 8px;
  padding: ${({ padding }) => padding};
`;
