/* eslint-disable no-throw-literal */
import { requestWithAuth, requestWithoutAuth } from 'utils/common/axios-utils';

import { IssueToken, SignOut } from 'features/redux/slices/accountSlice';

import {
  AccountFieldValidationPayload,
  AutoRefreshTokenPayload,
  ChangePasswordPayload,
  LogInApiPayload,
  LogInApiResponse,
  LogOutApiPayload,
  SendValidationCodePayload,
  SignupApiPayload,
  SignupApiResponse,
} from 'types/account/remote';

export const signupApi = async (signupInfo: SignupApiPayload) => {
  const { username, email, password, nickname } = signupInfo;

  return await requestWithoutAuth<SignupApiResponse>({
    method: 'post',
    url: `/accounts/`,
    data: { username, email, password, nickname, account_type: 2 },
  });
};

export const logInApi = async (payload: LogInApiPayload) => {
  return await requestWithoutAuth<LogInApiResponse>({
    method: 'post',
    url: `/accounts/login/`,
    data: payload,
  });
};

export const logOutApi = async (payload: LogOutApiPayload, sendbird: any, dispatch: any) => {
  return await requestWithoutAuth({
    method: 'post',
    url: `/accounts/logout/`,
    data: payload,
  })
    .then(() => {
      dispatch(SignOut());
      sendbird.disconnect();
    })
    .catch(() => {
      dispatch(SignOut());
      sendbird.disconnect();
    });
};

export const autoRefreshToken = async (account: AutoRefreshTokenPayload, dispatch: any, sendbird: any) => {
  if (account?.userInfo.access_token_expiration >= Date.now()) return;
  else await issueTokenRequestApi(account, dispatch, sendbird);
};

export const verifyTokenRequestApi = async (
  access_token: string,
  account: AutoRefreshTokenPayload,
  dispatch: any,
  sendbird: any
) => {
  if (account.userInfo.access_token_expiration >= Date.now()) {
    return;
  } else {
    return await requestWithoutAuth({
      method: 'post',
      url: `/accounts/token/verify/`,
      data: { token: access_token },
    }).catch(() => issueTokenRequestApi(account, dispatch, sendbird));
  }
};

export const issueTokenRequestApi = async (account: AutoRefreshTokenPayload, dispatch: any, sendbird: any) => {
  return await requestWithoutAuth({
    method: 'post',
    url: `/accounts/token/refresh/`,
    data: { refresh: account.userInfo.refresh_token },
  })
    .then(async (data) => {
      await dispatch(IssueToken(data));
      await window.location.reload();
    })
    .catch(async () => {
      dispatch(SignOut());
      sendbird.disconnect();
      window.location.replace('/login');
    });
};

export const accountFieldsValidation = async ({
  access_token,
  field,
  data,
  isSignUp = true,
}: AccountFieldValidationPayload) => {
  return isSignUp
    ? await requestWithoutAuth({
        method: 'post',
        url: `/accounts/validation/${field}/`,
        data,
        'Content-Type': 'multipart/form-data',
      })
    : await requestWithAuth(
        {
          method: 'post',
          url: `/accounts/validation/${field}/`,
          data,
          'Content-Type': 'multipart/form-data',
        },
        access_token
      );
};

export const sendValidationCode = async ({ field, data }: SendValidationCodePayload) => {
  return await requestWithoutAuth({
    method: 'patch',
    url: `/accounts/validation/${field}/`,
    data,
    'Content-Type': 'multipart/form-data',
  });
};

export const sendCodeToResetPassword = async (email: string) => {
  return await requestWithoutAuth({
    method: 'get',
    url: `/accounts/passwordreset/?email=${email}`,
  });
};

export const verifyCodeToResetPassword = async (formData: FormData) => {
  return await requestWithoutAuth({
    method: 'post',
    url: `/accounts/passwordreset/`,
    data: formData,
    'Content-Type': 'multipart/form-data',
  });
};

export const resetPassword = async (email: string) => {
  return await requestWithoutAuth({
    method: 'post',
    url: `/accounts/password/reset/`,
    data: { email },
  });
};

export const changePassword = async (payload: ChangePasswordPayload, access_token: string) => {
  return await requestWithAuth(
    {
      method: 'post',
      url: `/accounts/password/change/`,
      data: payload,
    },
    access_token
  );
};
